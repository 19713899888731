import React from "react"

export class Snowflakes extends React.Component {
  render() {
    return (
      <div className='snowflakes' aria-hidden='true'>
        <div className='snowflake'>
          ♡
        </div>
        <div className='snowflake'>
          ♡
        </div>
        <div className='snowflake'>
          ♡
        </div>
        <div className='snowflake'>
          ♡
        </div>
        <div className='snowflake'>
          ♡
        </div>
        <div className='snowflake'>
          ♡
        </div>
        <div className='snowflake'>
          ♡
        </div>
        <div className='snowflake'>
          ♡
        </div>
        <div className='snowflake'>
          ♡
        </div>
        <div className='snowflake'>
          ♡
        </div>
      </div>
    )
  }
}